import React, { useState } from 'react';
import { Text } from '../../styledComponents/Text';
import { MainContainer } from '../../styledComponents/MainContainer';
import { Content } from '../../styledComponents/Content';
import { Container } from '../../styledComponents/Container';
import { GlobalStyle } from '../../styledComponents/GlobalStyle';
import { Input } from '../../styledComponents/Input';
import { SearchButton } from '../../styledComponents/Button';  // Use the yellow search button
import { LoadingText } from '../../styledComponents/LoadingText';
import { useNavigate } from 'react-router-dom';
import Footer from '../Footer/Footer';

function Home() {
    const [username, setUsername] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        setUsername(event.target.value);
    };

    const handleSearch = () => {
        setLoading(true);
        fetch("/download_snapchat_story", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ username })
        })
        .then(res => res.json())  // Parse the response as JSON
        .then(data => {
            setLoading(false);
            
            if (data.stories || data.highlights) {  // Check if stories or highlights exist
                navigate(`/profile/${username}`, { state: { stories: data.stories, highlights: data.highlights } });
            } else {
                alert(data.error || "No stories or highlights found.");
            }
        })
        .catch(error => {
            setLoading(false);
            console.error("Error:", error);
            alert("An error occurred, please try again.");
        });
    };

    return (
        <>
            <GlobalStyle />
            <h1><center>Snapchat Downloader</center></h1>
            <MainContainer>
                <Content>
                    <Container>
                        <h2>Download Snapchat Stories Easily</h2>

                        <Input
                            type="text"
                            placeholder="Enter Snapchat username without @"
                            value={username}
                            onChange={handleInputChange}
                        />
                        <SearchButton onClick={handleSearch}>Search</SearchButton>
                        {loading && <LoadingText>Loading... please wait</LoadingText>}
                        <p>
                            Welcome to the Snapchat Story Downloader. Our tool allows you to easily download and view Snapchat stories 
                            and highlights from any public account. Whether you want to archive a special moment or keep a collection 
                            of your favorite content, our downloader helps you capture it all with just a few clicks.
                        </p>
                        <p>
                            Simply enter the username of the Snapchat account below, and you'll be able to download stories or highlights 
                            directly to your device.
                        </p>

                        <h3>How to Use the Snapchat Downloader</h3>
                        <p>
                            1. Enter the Snapchat username without the '@' symbol in the field below.<br />
                            2. Click "Search" to fetch stories and highlights.<br />
                            3. Select the stories or highlights you want to download.<br />
                            4. Save them to your device and enjoy!
                        </p>

                        <h3>Why Use Our Snapchat Downloader?</h3>
                        <p>
                            Our Snapchat Viewer is fast, reliable, and easy to use. No need to install any software or create an account. 
                            Just enter the Snapchat username, and you'll have the stories or highlights ready to download in seconds. 
                            Perfect for capturing memories, creating collections, or keeping track of your favorite content creators.
                        </p>

                        <h3>Privacy and Safety</h3>
                        <p>
                            We respect your privacy and ensure that all downloads are secure. This tool only works for public Snapchat 
                            accounts, and we do not store any data or content from users.
                        </p>
                    </Container>
                </Content>
                <Footer />
            </MainContainer>
        </>
    );
}

export default Home;
